import Image from 'next/image';
import Link from 'next/link';
import Head from '@/components/global/Head';
import PBSKidsLogo from '@/public/puma/images/logos/PBSKidsLogo.svg';
import styles from './_404.module.scss';
import { getResolvedPageThemeData } from '@/utils/theming-system';
import { error404PageTheme } from '@/utils/theming-system/errorPageTheme';
import { ResolvedPageThemes, ThemeCandidate } from '@/utils/theming-system/types';
import ThemeStyles from '@/components/global/ThemeStyles';

type ErrorPageProps = {
  resolvedPageThemes: ResolvedPageThemes,
};

export async function getStaticProps() {
  const errorThemeCandidate: ThemeCandidate = {
    themeContextName: 'page',
    themes: [ error404PageTheme ],
    sourceDescription: 'Hardcoded error page theme',
  };

  const resolvedPageThemes = await getResolvedPageThemeData(null, [ errorThemeCandidate ]);

  return {
    props: {
      resolvedPageThemes,
      errorPage: true,
    },
  };
}

export default function ErrorPage404({
  resolvedPageThemes,
}: ErrorPageProps) {
  return (
    <div className={`contain ${styles.wrapper}`}>
      <Head
        pageTitle='Whoops!'
      />

      <ThemeStyles resolvedPageThemes={resolvedPageThemes} />

      <a
        href={'/'}
        className={styles.kidsLogo}>
        <PBSKidsLogo
          role='img'
          title='PBS Kids Home!'
        />
      </a>

      <div className={styles.fallingKidsbox}>
        <div className={`${styles.kidOne} ${styles.slideDown}`}></div>
        <div className={`${styles.kidTwo} ${styles.slideDown2}`}></div>
        <div className={`${styles.kidThree} ${styles.slideDown3}`}></div>
      </div>

      <h1 className={styles.whoopsText}>
        <span className={styles.whoopsTextBig}>Whoops!<br/></span>
        <span className={styles.whoopsTextSmall}>
          We can&apos;t seem to find that page.
          Check to see if that address is correct or pick one of the following options below:
        </span>
      </h1>

      <div className={styles.boxes}>
        <div className={`${styles.boxButton} ${styles.pbsKidsHomeBox}`}>
          <a href={'/'} title='PBS Kids Home!'>
            <PBSKidsLogo
              role='img'
              title='PBS Kids Home!'
            />
          </a>
        </div>

        <div className={styles.boxButton}>
          <Link href='/videos' title='PBS Kids Videos!'>
            <Image
              width='237'
              height='133'
              src='/puma/images/error-pages/button-videos.svg'
              alt=''
            />
          </Link>
        </div>

        <div className={styles.boxButton}>
          <Link href='/games' title='PBS Kids Games!'>
            <Image
              width='237'
              height='133'
              src='/puma/images/error-pages/button-games.svg'
              alt=''
            />
          </Link>
        </div>
      </div>
    </div>
  );
}
